.team-cards-table {
  tr {
    th {
      width: 50%;
      padding: 4px;
      text-align: left;
      border: none;
      color: #676767;
      font-size: 0.8rem;
      font-family: "Poppins-Light";
      transition: 0.3s ease-in;
      &:hover {
        transition: 0.3s ease-in;
        transform: scale(1);
      }
    }
    td {
      position: relative;
      text-align: left;
      border: none;
      padding: 1px 5px;
      letter-spacing: 0.1px;
      font-family: "Poppins-Regular";
      font-size: 0.7rem;
      color: #1d1d1dcc;
      transition: 0.3s ease-in;
      &:hover {
        transition: 0.3s ease-in;
        transform: scale(1);
      }
      img {
        // position: absolute;
        height: 0.9rem;
        // left: -1rem;
        // top: 2px;
      }
      .image {
        position: inital;
      }
    }
  }
}
