.view-players-table {
  .table-responsive {
    padding: 0;
    padding-left: 3.5rem;
  }
  background-color: #fff;
  padding: 1.5rem 4rem 1.5rem 2rem;
  overflow-y: scroll;
  max-height: 40rem;
  @media screen and (orientation: landscape) {
    max-height: 80%;
    padding: 1rem 3rem 1rem 0rem;
    .table-responsive {
      padding: 0;
      padding-left: 3.5rem;
    }
  }
  table {
    th,
    tr,
    td {
      border: none;
      outline: none;
      color: #1d1d1dcc;
    }
    tr {
      margin: 2.5rem;
      height: 2.4rem;
      vertical-align: middle;
    }
    th {
      font-family: "Poppins-Light";
      font-weight: 600;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    td {
      font-family: "Poppins-Semibold";
      border-bottom: 0.5px solid #9e9dd3;
      font-size: 12px;
      letter-spacing: 0.8px;
    }
  }
}

.overseas {
  // z-index: 999;
  // left: 3rem;
  // position: absolute;
  height: 1.2rem;
}
