.main {
  margin: auto;
  max-width: 500px;

  .user-container {
    // height: 45rem;
    display: flex;
    justify-content: center;
    padding: 0;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    .purse-remain {
      padding: 1rem 0;
      display: flex;
      width: 85%;
      justify-content: center;
      .purse-remain-div {
        min-width: 0;
        display: flex;
        width: 100%;
        // max-width: 11rem;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-wrap: wrap;
      }
    }
    .team-details {
      background-color: #0040a8;
      padding: 0;
      .dialog-box .box {
        margin: 0;
      }
    }
    .team-details-1 {
      border-right: 1px solid #dcdcdc;
    }
    .team-details-2 {
      border-left: 1px solid #dcdcdc;
    }
    .start-btn {
      &:hover {
        background-color: #216fec !important;
        color: #dcdcdc;
      }
    }
    .round-btn-rtm {
      &:hover {
        color: #920202;
        background-color: #fff;
        border-color: #920202;
      }
    }
  }
}

// #view-player-mobile {
//   @media (orientation: portrait) {
//     height: 100vw;

//     -webkit-transform: rotate(90deg);
//     -moz-transform: rotate(90deg);
//     -o-transform: rotate(90deg);
//     -ms-transform: rotate(90deg);
//     transform: rotate(90deg);
//   }
// }
