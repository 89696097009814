.mobile-nav {
  display: flex;
  position: absolute;
  width: 100%;
  justify-content: space-between;
  font-family: "Poppins-Regular";
  padding: 0.5rem 2rem;
  .light {
    font-family: "Poppins-Light";
  }
  .right {
    text-decoration: none;
    color: black;
    outline: none;
    border: none;
    text-align: center;
  }
}
