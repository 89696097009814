* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.cbtnClass {
  width: fit-content;
  border: none;
  color: #fff;
  border-radius: 7px;
  background-color: #0040a8;
  font-family: Poppins-Regular;
  font-size: 12px;
  letter-spacing: 0.5px;
  padding: 0.7rem 1rem;
  border-radius: 5px;
  font-family: "Poppins-Medium";
  transition: 0.2s ease;
  &:hover {
    transition: 0.2s ease;
    background-color: rgb(28, 65, 199) !important;
    color: white;
  }
  a {
    color: white;
    text-decoration: none;
    &:hover {
      color: white;
    }
  }
}
