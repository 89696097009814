.bidding-amount {
  display: flex;
  min-height: 4rem;
  min-width: 6rem;
  justify-content: center;
  background-color: transparent;
  font-family: "Poppins-SemiBold";
  font-size: 1.8rem;
  color: #f9f8f8;
  align-items: center;
  border: 2px solid #3892fe;
  border-radius: 8px;
  transition: 0.2s ease-in-out;
  &.selected {
    transition: 0.2s ease-in-out;
    color: #f9f8f8;
    background-color: #3892fe;
  }
}
