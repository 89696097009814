.player-tabs {
  flex-direction: column;
}
.col-sm-3,
.col-sm-9 {
  padding: 0;
  .tab-content {
    padding: 0;
    margin: 3px 0;
  }
  .nav-pills {
    padding: 0;
    text-transform: uppercase;
    font-family: "Poppins-Bold";
    color: #dcdcdc;
    text-align: center;
    .nav-link {
      margin: 3px 0;
    }
    .nav-link.active,
    .nav-pills .show > .nav-link {
      border-radius: 0;
      color: #dcdcdc;
      background-color: #0040a8;
    }
  }
}
