.dialog-box {
  font-family: "Poppins-bold";
  max-width: 25rem;
  min-width: 20rem;
  // transition: opacity 1s ease;

  .box {
    &.image {
      background-position: center;
      background-size: 15rem;
      background-repeat: no-repeat;
      height: 11rem;
    }
    span,
    .col {
      padding: 0;
    }
    word-spacing: 1px;
    letter-spacing: 1px;
    display: flex;
    text-align: center;
    max-width: 20rem;
    min-height: 2.4rem;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    align-content: center;
    &.blue {
      height: fit-content;
      transition: all 0.3s ease-in-out;
      clip-path: inset(0 0 0 0) !important;
      &.hide {
        clip-path: inset(0 0 100% 0) !important;
        transition: all 0.3s ease-in-out;
        // transition: opacity 1s ease;
      }
      background-color: #0040a8;
      z-index: 80;
      position: relative;
      span,
      .col {
        color: #f9f8f8;
      }
    }
    &.white {
      background-color: #fff;
      z-index: 0;
      position: relative;
      span,
      .col {
        color: #313131;
      }
    }
    &.header {
      span,
      .col {
        text-transform: uppercase;
      }
    }
  }
}

.circular-progress-circle {
  position: absolute;
  top: 33px;
  right: -98px;
  z-index: 12;
  clip-path: inset(0 98px 9rem 0);
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  overflow: hidden;
}
