.player-list-container {
  padding-top: 1rem;
  max-width: 90%;
  .table {
    cursor: pointer;
    margin: 10px auto;
    transition: 0.2s ease;
  }
  .start-btn {
    border-radius: 0;
    background-color: #3892fe;
    padding: 8px;
    font-size: 1.1rem;
    border: none;
    &:hover {
      color: #3892fe;
      background-color: #f5f5f5;
    }
    &.resume {
      color: #f5f5f5;
      background-color: #7aa72b;
      &:hover {
        color: #7aa72b;
        background-color: #f5f5f5;
      }
    }
  }
}
