.dialog-box.vertical {
  flex-direction: column;
  align-content: center;
  .tenduaImg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 15rem;
    min-height: 13rem;
  }
  .box.white.big {
    flex-direction: column;
    min-height: 6rem;
    justify-content: center;
    .sold {
      width: 100%;
      .gavel {
        background-size: 6rem;
        background-repeat: no-repeat;
        background-position: right center;
        height: auto;
      }
      .sold-title {
        color: #fff;
        font-family: "Poppins-Bold";
        font-size: 2.5rem;
        line-height: 2rem;
      }
      .bid-value.white {
        text-align: left;
        font-size: 1.7rem;
        line-height: 2rem;
      }
    }
    .current-bid,
    .bid-value {
      width: 100%;
      text-align: center;
    }
    .current-bid {
      font-family: "Poppins-Semibold";
    }

    .bid-value {
      font-size: 2rem;
      &.white {
        font-family: "Poppins-Regular";
        color: #fff;
      }
    }

    &.unsold {
      background-color: #920202;
      .unsold {
        color: #fff;
      }
    }
    .unsold {
      font-size: 2.5rem;
    }
  }
}
