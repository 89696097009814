.biddercontainer {
  font-family: "Poppins-Regular";
  width: 25%;
  display: flex;
  height: 100vh;
  justify-content: flex-end;
  margin: auto;
  flex-direction: column;
  position: relative;
  @media screen and (max-width: 1008px) {
    width: 30% !important;
  }
  @media screen and (max-width: 916px) {
    width: 36% !important;
  }
  @media screen and (max-width: 839px) {
    width: 41% !important;
  }
  @media screen and (max-width: 737px) {
    width: 45% !important;
  }
  @media screen and (max-width: 673px) {
    width: 50% !important;
  }
  @media screen and (max-width: 605px) {
    width: 60% !important;
  }
  @media screen and (max-width: 503px) {
    width: 100% !important;
  }
  .up {
    background-image: url("../../../resources/images/meme.png");
    background-size: cover;
    position: absolute;
    width: 100%;
    transform: translateY(-100%);
    height: 50%;
    // img {
    //   height: fit-content;
    //   width: 100%;
    //   // height: 300px;
    // }
  }
  .down {
    min-height: 50vh;

    z-index: 99;
    // transform: translateY(12rem);
    padding: 30px 35px;
    border-radius: 25px;

    // transform: translateY(-50px);
    background-color: #fff;

    .welcome {
      text-transform: uppercase;
      font-family: "Poppins-Medium";
      text-align: center;
      color: #232323;
      font-size: 14px;
    }
    .spectors {
      text-transform: uppercase;
      font-family: "Poppins-Bold";
      text-align: center;
      color: #0040a8;
      margin-bottom: 7px;
    }
    .form {
      .loginForm {
        .inputdiv {
          padding: 5rem auto !important;

          label {
            display: block;
            position: relative;
            input {
              width: 100%;
              border: none;
              background-color: #e9eff6;
              padding: 0.5rem 1rem;
              border-radius: 20px;
              margin-bottom: 10px;
              font-size: 13px;
              &:focus {
                outline: none;
              }

              &::placeholder {
                color: #bdbdbd;
              }
            }
            &:nth-child(1) {
              .eyeSvg {
                cursor: pointer;
                position: absolute;
                right: 12px;
                top: 7px;
                .eye {
                  height: 13px;
                }
              }
            }
          }

          .inputdiv1 {
            margin-bottom: 10px;
          }

          .inputdiv2 {
            margin-bottom: 15px;
          }
        }
      }
    }
    .logoContainer {
      padding: 35px 0px;
      .logo {
        display: flex;
        width: 35%;
        margin: auto;
      }
    }
  }
}
