.purse-remaining {
  table {
    th,
    td {
      border: none;
      outline: none;
    }
    th {
      font-family: "Poppins-Light";
      font-weight: 400;
      font-size: 12px;
      color: #f9f8f8cc;
      text-transform: uppercase;
    }
    td {
      font-family: "Poppins-Regular";
      color: #f9f8f8;
      border-bottom: 0.5px solid #9e9dd3;
      font-size: 13px;
    }
  }
}

.table-responsive {
  padding: 0;
}
