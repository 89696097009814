.round-btn-custom {
  width: fit-content;
  min-width: 10rem;
  //   max-width: 15rem;
  max-height: 3rem;
  border-radius: 0.7rem;
  word-spacing: 3px;
  font-size: 0.9rem;
  background: #920202;
  border-color: #920202;
  letter-spacing: 1px;
  padding: 12px;
  font-family: "Poppins-Bold";

  &:hover,
  &:active {
    background-color: aliceblue;
    color: #3892fe;
  }
}
.round-nobtn-custom {
  width: fit-content;
  min-width: 10rem;
  //   max-width: 15rem;
  max-height: 3rem;
  border-radius: 0.7rem;
  word-spacing: 3px;
  font-size: 0.9rem;
  background: #920202;
  border-color: #920202;
  letter-spacing: 1px;
  padding: 12px;
  font-family: "Poppins-Bold";
}
