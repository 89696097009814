.bottom {
  // margin: 2rem 0;
}
#root {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  background-image: url("../resources/images/background-plain.png");
}
