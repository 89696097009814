.curator-container {
  .top {
    .middle,
    .right {
      div {
        justify-content: center;
        .white-btn-custom {
          border-radius: 1.3rem;
          font-size: 1rem;
          background-color: #fff;
          color: #3892fe;
          border: 2px solid #3892fe;
          padding: 11px 1.8rem;
          &:hover,
          &:active {
            color: black;
            border-color: black;
          }
        }

        .blue-btn-custom {
          border-radius: 1.3rem;
          font-size: 0.9rem;
          background-color: #3892fe;
          color: #fff;
          border: 2px solid #3892fe;
          padding: 11px 1.8rem;
          &:hover,
          &:active {
            background-color: #fff;
            color: #3892fe;
            border: 2px solid #3892fe;
          }
        }
        .red-btn-custom {
          border-radius: 1.3rem;
          font-size: 0.9rem;
          background-color: #920202;
          color: #fff;
          border: 2px solid #920202;
          padding: 11px 1.8rem;
          &:hover,
          &:active {
            background-color: #fff;
            color: #f02525;
            border: 2px solid #920202;
          }
        }
        .sell {
          height: 8rem;
          background-color: #0bd18a;
          padding: 0rem 4.8rem 0rem;
          transform: scale(1.3);
          font-size: 1.3rem;
          border-color: #0bd18a;
          &:hover,
          &:active {
            color: #0bd18a;
            border: 1px solid #0bd18a;
            background-color: #fff;
          }
        }
        .unsold {
          padding: 11px 1.8rem;
          font-size: 0.8rem;
          &:hover,
          &:active {
            background-color: #fff;
            color: #f02525;
          }
        }
      }
    }

    .right {
      div {
        justify-content: right;
        margin-right: 4rem;
      }
    }
  }
}

.save-model {
  background-color: #19cc9d;
  border-color: #19cc9d;
  &:hover {
    background-color: #0a9d76;
    border-color: #0a9d76;
  }
}
.discard-model {
  background-color: #1d2a46d8;
  border-color: #1d2a46d8;
  &:hover {
    background-color: #1d2a46;
    border-color: #1d2a46;
  }
}
