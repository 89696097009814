.loading-modal {
  transition: all 0.2s ease-in-out;
  display: flex;
  opacity: 0;
  z-index: -999;
  position: absolute;

  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  // top: 72px;
  height: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(39.60016632080078px);

  &.open {
    opacity: 1;
    z-index: 900;
  }

  &.close {
    opacity: 0;
    z-index: -999;
  }

  z-index: 900;
  .spann {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: Barlow;
    font-size: 2rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    z-index: 999;
    min-width: 18rem;
    border: 2px solid #c0207680;
    min-height: 18rem;
    border-radius: 0.7rem;
    font-family: "Poppins-Bold";
    font-size: 1.5rem;
    padding: 2rem 8rem;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.1) -1.52%,
      rgba(192, 32, 118, 0.1) 13.59%
    );
    margin: auto;
    text-align: center;
    margin-top: 17rem;
  }

  &.mobile {
    font-size: 1rem;
    top: 40px;
    .spann {
      font-size: 1.2rem;
      margin-top: 11rem;
      width: 80%;
      padding: 0 2rem 1rem;
    }
  }
}
